import config from "config/config";
import React, { useEffect, useState } from "react";
import { apiGetWeatherDetails } from "apis/masterData";
import NcImage from "shared/NcImage/NcImage";
import { format } from "date-fns";
import TicketsLoader from "components/TicketsLoader/TicketsLoader";

const tempData = {
  location: {
    name: "Dubai",
    country: "United Arab Emirates",
    region: "Dubai",
    lat: "25.252",
    lon: "55.280",
    timezone_id: "Asia/Dubai",
    localtime: "2023-12-05 13:07",
    localtime_epoch: 1701781620,
    utc_offset: "4.0",
  },
  current: {
    observation_time: "09:07 AM",
    temperature: 29,
    weather_code: 113,
    weather_icons: [
      "https://cdn.worldweatheronline.com/images/wsymbols01_png_64/wsymbol_0001_sunny.png",
    ],
    weather_descriptions: ["Sunny"],
    wind_speed: 7,
    wind_degree: 290,
    wind_dir: "WNW",
    pressure: 1017,
    precip: 0,
    humidity: 48,
    cloudcover: 0,
    feelslike: 30,
    uv_index: 7,
    visibility: 10,
    is_day: "yes",
  },
};
function TMWeatherWidget() {
  const [weather, setWeather] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  const getWeather = async () => {
    try {
      const res = await apiGetWeatherDetails({
        region: config.region,
      });

      if (res && res.success === true) {
        setWeather(res.data);
      } else {
        setWeather(tempData);
      }
    } catch (error) {
      setWeather(tempData);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getWeather();
  }, []);

  // console.log(weather);
  return (
    <div>
      {isLoading === true ? (
        <TicketsLoader />
      ) : (
        weather && (
          <div className="flex justify-center">
            <div className="card  min-w-sm max-w-sm border  bg-gray-100  transition-shadow   shadow-lg hover:shadow-shadow-xl w-full bg-slate-800 text-purple-50 rounded-md">
              <h2 className="text-md mb-2 px-4 pt-4">
                <div className="flex justify-between">
                  <div className="badge relative top-0">
                    <span className="mt-1 py-1 h-10px text-md font-semibold w-12px  rounded right-1 bottom-1 px-4">
                      {weather?.location.name}
                    </span>
                  </div>
                  <span className="text-lg font-bold ">
                    {format(new Date(), "hh:mm a")}
                  </span>
                </div>
              </h2>

              <div className="flex items-center p-2">
                <div className="flex flex-col justify-center items-center w-48">
                  <span className="mb-1">
                    {weather?.current.weather_descriptions[0]}
                  </span>
                  <NcImage
                    src={weather?.current.weather_icons[0]}
                    className="rounded"
                  />
                </div>
              </div>
              <div className="text-md pt-1 pb-4 px-4">
                <div className="flex justify-between items-center">
                  <div className="space-y-2">
                    <span className="flex space-x-2 items-center">
                      <svg
                        height="20"
                        width="20"
                        viewBox="0 0 32 32"
                        className="fill-current"
                      >
                        <path d="M13,30a5.0057,5.0057,0,0,1-5-5h2a3,3,0,1,0,3-3H4V20h9a5,5,0,0,1,0,10Z"></path>
                        <path d="M25 25a5.0057 5.0057 0 01-5-5h2a3 3 0 103-3H2V15H25a5 5 0 010 10zM21 12H6V10H21a3 3 0 10-3-3H16a5 5 0 115 5z"></path>
                      </svg>{" "}
                      <span>{weather?.current.wind_speed} km/h</span>
                    </span>
                    <span className="flex space-x-2 items-center">
                      <svg
                        height="20"
                        width="20"
                        viewBox="0 0 32 32"
                        className="fill-current"
                      >
                        <path d="M16,24V22a3.2965,3.2965,0,0,0,3-3h2A5.2668,5.2668,0,0,1,16,24Z"></path>
                        <path d="M16,28a9.0114,9.0114,0,0,1-9-9,9.9843,9.9843,0,0,1,1.4941-4.9554L15.1528,3.4367a1.04,1.04,0,0,1,1.6944,0l6.6289,10.5564A10.0633,10.0633,0,0,1,25,19,9.0114,9.0114,0,0,1,16,28ZM16,5.8483l-5.7817,9.2079A7.9771,7.9771,0,0,0,9,19a7,7,0,0,0,14,0,8.0615,8.0615,0,0,0-1.248-3.9953Z"></path>
                      </svg>{" "}
                      <span>{weather?.current.humidity} %</span>
                    </span>
                  </div>
                  <div>
                    <h1 className="text-5xl">
                      {" "}
                      {weather?.current.temperature}°{" "}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
}

export default TMWeatherWidget;
